import PropTypes from "prop-types"
import React from "react"

const SimplePage = ({children}) => {
  return (
	<div className="simple-page p-5 lg:p-12 bg-dark-night">
        <div className="max-w-screen-lg mx-auto">
            {children}
        </div>
	</div>
  )
}

SimplePage.propTypes = {
  children: PropTypes.node.isRequired
}

export default SimplePage
